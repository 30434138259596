.sidebar .logo .logo-mini .logo-img img {
  border-radius: 50%;
  width: 30px;
}
.sidebar {
  height: 100%;
}
@include media-breakpoint-down(sm) {
  .sidebar {
    height: calc(var(--vh, 1vh) * 100) !important;
  }
}
@include media-breakpoint-down(md) {
  html.nav-open body {
    position: relative;
    overflow-x: hidden;
  }
  html.nav-open .main-panel {
    overflow: auto;
    min-height: unset;
  }
  html.nav-open .wrapper {
    overflow: hidden;
  }
}
