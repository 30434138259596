select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url('https://img.icons8.com/material/24/000000/sort-down.png') right / 20px no-repeat #fff;
  padding-right: 20px;
}

select.customDropdown::-ms-expand {
  display: none;
}

select.customDropdown {
  outline: none;
  overflow: hidden;
  text-indent: 0.01px;
  text-overflow: '';
  background: url('https://img.icons8.com/material/24/000000/sort-down.png') no-repeat right #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  -webkit-transform: none;
  -moz-transform: none;
  -o-transform: none;
  -ms-transform: none;
  transform: none;
}

#customRight {
  display: flex;

  justify-content: flex-end;
}

#id2 {
  overflow: hidden;
}

#id3 {
  float: right;
  clear: both;
}

#local_video {
  width: 1240.53px;
  height: 720px;
  position: relative;
}

#local_video {
  width: 1240.53px;
  height: 720px;
  position: relative;
  margin: 0 auto;
  display: block;
}

#local_video video {
  width: 1240.53px;
  height: 720px;
  position: relative !important;
}

local_video {
  width: 1240.53px;
  height: 720px;
  position: relative;
}

local_video {
  width: 1240.53px;
  height: 720px;
  position: relative;
  margin: 0 auto;
  display: block;
}

local_video video {
  width: 1240.53px;
  height: 720px;
  position: relative !important;
}

#my_video_2 {
  min-height: inherit;
}

.edit_title {
  cursor: text;
}

.edit_title:hover,
.edit_title:active {
  color: #1d8cf8;
}

.inline-text_copy--active,
.inline-text_input--active {
  font: inherit;
  color: inherit;
  text-align: inherit;
  padding: 0;
  background: none;
  border: none;
  outline: none;
}

.inline-text_copy--active {
  cursor: text;
}

.inline-text_copy--hidden,
.inline-text_input--hidden {
  display: none;
}

.inline-text_input--active {
  text-align: left;
}

#parent {
  width: 100% !important;
  box-sizing: border-box !important;
  padding-left: 2rem !important;
  padding-right: 0.5rem !important;
}

.child-col {
  width: 66.6667%;
}

.child {
  padding-left: 2rem !important;
  padding-right: 0rem !important;
}

.all-page-container {
}

.react-pdf__Page__canvas {
}

.PDFDocumentWrapper canvas {
  width: 100% !important;
  height: auto !important;
}

#changing_display {
  display: none;
}

#homepage_top_section {
  height: 35rem;
}

#homepage_white_section {
  height: 145rem;
}

#mobile_section_center {
  text-align: left;
}

#sort_width {
  width: 120%;
  margin-left: -10%;
}

.lg-mobil {
  display: none;
}

.sidebar-brand {
  min-height: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar-logo {
  position: relative;
}

.sidebar-logo__pro {
  position: absolute;
  top: 2px;
  right: 0;
  transform: translate(60%, -50%);
  background: #ee7734;
  background-image: linear-gradient(to bottom left, #ee7734, #ef3616, #ee7734);
  color: #fff;
  font-size: 10px;
  line-height: 1.2;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 2px 7px;
  width: auto;
}

.sidebar-logo__pro.navbar {
  top: -3px;
}

.spinner-border .visually-hidden {
  display: none;
}

.form-select {
  border: 1px solid rgba(110, 95, 123, 0.15);
  border-radius: 4px;
  margin-top: 16px;
  background-color: #ffffff;
  cursor: pointer;
  font-size: 0.75rem;
  color: #222a42;
  padding: 10px 18px 10px 18px;
  width: 100%;
}

.form-select:focus {
  outline: none;
}

.btn {
  font-weight: 400 !important;
}

.alert .btn-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 20px;
  height: 20px;
  outline: none !important;
}

.alert .btn-close::before,
.alert .btn-close::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  height: 2px;
  border-radius: 4px;
  background-color: #fff;
}

.alert .btn-close::before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.alert .btn-close::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

@media only screen and (max-width: 991px) {
  .lg-mobil {
    display: block;
  }

  #mobile_absent {
    display: none;
  }

  .color-navbar {
    position: absolute !important;
    width: 100%;
    top: 100%;
    left: 0;
    background-color: #fff;
    padding: 10px 5px;
  }
}

@media (max-width: 1200px) {
  .sidebar-logo__pro {
    top: -3px;
  }
}

@media only screen and (max-width: 480px) {
  #mobile_only {
    display: block;
  }

  #homepage_image {
    height: 16rem;
    width: 100%;
  }

  #sort_width {
    width: 90%;
    margin-left: 0%;
    text-align: center;
  }

  #mobile_section_center_images {
    text-align: left;
    height: 16rem;
  }

  #mobile_section_center {
    text-align: center;
  }

  #changing_display {
    display: block;
  }

  #homepage_top_section {
    height: 50rem;
  }

  #homepage_white_section {
    height: 170rem;
  }

  #mobile-features-text {
    width: 110%;
  }

  #mobile-features-text-2 {
    width: 120%;
  }
}

@media only screen and (max-width: 720px) {
  #mid_screen_absent {
    display: none;
  }

  #sort_width {
    width: 90%;
    margin-left: 0%;
    text-align: center;
  }

  #mobile_section_center {
    text-align: center;
  }

  #homepage_top_section {
    height: 50rem;
  }

  #changing_display {
    display: block;
  }

  #mobile_section_center_images {
    margin-left: auto;
    margin-right: auto;
    height: 20rem;
  }

  #homepage_white_section {
    height: 170rem;
  }

  #contact_us_01 {
    height: 22.5rem;
  }
}

@media only screen and (max-width: 991px) {
  .child-col {
    width: 100%;
  }
}

@media only screen and (min-width: 480px) {
  #mobile_only {
    display: none;
  }
}

/* CSS variables. */
:root {
  --PhoneInput-color--focus: #03b2cb;
  --PhoneInputInternationalIconPhone-opacity: 0.8;
  --PhoneInputInternationalIconGlobe-opacity: 0.65;
  --PhoneInputCountrySelect-marginRight: 0.35em;
  --PhoneInputCountrySelectArrow-width: 0.3em;
  --PhoneInputCountrySelectArrow-marginLeft: var(--PhoneInputCountrySelect-marginRight);
  --PhoneInputCountrySelectArrow-borderWidth: 1px;
  --PhoneInputCountrySelectArrow-opacity: 0.45;
  --PhoneInputCountrySelectArrow-color: inherit;
  --PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountrySelectArrow-transform: rotate(45deg);
  --PhoneInputCountryFlag-aspectRatio: 1.5;
  --PhoneInputCountryFlag-height: 1em;
  --PhoneInputCountryFlag-borderWidth: 1px;
  --PhoneInputCountryFlag-borderColor: rgba(0, 0, 0, 0.5);
  --PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountryFlag-backgroundColor--loading: rgba(0, 0, 0, 0.1);
}

.PhoneInput {
  /* This is done to stretch the contents of this component. */
  display: flex;
  align-items: center;
}

.PhoneInputInput {
  /* The phone number input stretches to fill all empty space */
  flex: 1;
  /* The phone number input should shrink
	   to make room for the extension input */
  min-width: 0;
}

.PhoneInputCountryIcon {
  width: calc(1em * 1.5);
  width: calc(var(--PhoneInputCountryFlag-height) * var(--PhoneInputCountryFlag-aspectRatio));
  height: 1em;
  height: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--square {
  width: 1em;
  width: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--border {
  /* Removed `background-color` because when an `<img/>` was still loading
	   it would show a dark gray rectangle. */
  /* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom. */
  background-color: rgba(0, 0, 0, 0.1);
  background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
  /* Border is added via `box-shadow` because `border` interferes with `width`/`height`. */
  /* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom,
	   so an additional "inset" border is added. */
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5), inset 0 0 0 1px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor),
    inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor);
}

.PhoneInputCountryIconImg {
  /* Fixes weird vertical space above the flag icon. */
  /* https://gitlab.com/catamphetamine/react-phone-number-input/-/issues/7#note_348586559 */
  display: block;
  /* 3rd party <SVG/> flag icons won't stretch if they have `width` and `height`.
	   Also, if an <SVG/> icon's aspect ratio was different, it wouldn't fit too. */
  width: 100%;
  height: 100%;
}

.PhoneInputInternationalIconPhone {
  opacity: 0.8;
  opacity: var(--PhoneInputInternationalIconPhone-opacity);
}

.PhoneInputInternationalIconGlobe {
  opacity: 0.65;
  opacity: var(--PhoneInputInternationalIconGlobe-opacity);
}

/* Styling native country `<select/>`. */

.PhoneInputCountry {
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-right: 0.35em;
  margin-right: var(--PhoneInputCountrySelect-marginRight);
}

.PhoneInputCountrySelect {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  border: 0;
  opacity: 0;
  cursor: pointer;
}

.PhoneInputCountrySelect[disabled] {
  cursor: default;
}

.PhoneInputCountrySelectArrow {
  display: block;
  content: '';
  width: 0.3em;
  width: var(--PhoneInputCountrySelectArrow-width);
  height: 0.3em;
  height: var(--PhoneInputCountrySelectArrow-width);
  margin-left: 0.35em;
  margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
  border-style: solid;
  border-color: inherit;
  border-color: var(--PhoneInputCountrySelectArrow-color);
  border-top-width: 0;
  border-bottom-width: 1px;
  border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  border-left-width: 0;
  border-right-width: 1px;
  border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  -webkit-transform: rotate(45deg);
  -webkit-transform: var(--PhoneInputCountrySelectArrow-transform);
  transform: rotate(45deg);
  transform: var(--PhoneInputCountrySelectArrow-transform);
  opacity: 0.45;
  opacity: var(--PhoneInputCountrySelectArrow-opacity);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
  opacity: 1;
  color: #03b2cb;
  color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
  box-shadow: 0 0 0 1px #03b2cb, inset 0 0 0 1px #03b2cb;
  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus),
    inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
  opacity: 1;
  color: #03b2cb;
  color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.phone-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.phone-wrap label {
  color: #1f2539 !important;
}

.phone-number {
  display: flex;
  align-items: center;
  background: #fefcff;
  border: 1px solid #8e909c;
  border-radius: 7px;
  padding: 6px 8px;
  width: 100%;
  height: 36px;
}

.phone-number.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.phone-number select {
  border: none;
  outline: none;
  cursor: pointer;
  height: 100%;
}

.phone-number__select {
  width: 65px;
  position: relative;
  flex: none;
}

.phone-number input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  padding: 0 8px;
  margin-left: 4px;
  border-left: 1px solid #8e909c;
  font-size: 14px;
}

.phone-number input:-webkit-autofill,
.phone-number input:-webkit-autofill:hover,
.phone-number input:-webkit-autofill:focus,
.phone-number input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
}

.player {
  align-items: center;
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 10px;
  margin-bottom: 16px;
}

.player-wrap .player {
  background-color: gray;
}

.song {
  user-select: none;
}

.song__title {
  text-align: left;
  margin: 0;
  color: #fff !important;
  font-size: 25px;
  font-weight: 400;
  text-align: center;
}

.song__artist {
  margin: 0;
  color: #fff !important;
  font-family: 'Rock Salt', cursive;
  font-weight: normal;
  font-size: 20px;
}

.audio-btn {
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin: 0 0 16px 0;
}

.audio-btn svg {
  max-width: 60%;
  max-height: 60%;
  display: block;
}

.player__button {
  width: fit-content;
  margin-bottom: 15px;
  background-color: transparent;
  border: none;
}

.player__button svg {
  font-size: 4em;
  color: white;
}

.player__button:focus {
  outline: none;
}

.player__button:hover {
  cursor: pointer;
}

.player__button:hover svg {
  color: greenyellow;
}

.controls {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.controls__buttons .audio-btn {
  font-size: 14px !important;
  font-weight: 600 !important;
  width: 45px !important;
}

.bar {
  user-select: none;
  width: 90%;
  display: flex;
  align-items: center;
}

.bar__progress {
  flex: 1;
  border-radius: 5px;
  margin: 0 20px;
  height: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.bar__time {
  color: white;
  font-size: 20px;
  min-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bar__progress__knob {
  position: relative;
  height: 25px;
  width: 25px;
  border: 1.5px solid white;
  border-radius: 50%;
  background-color: orange;
}

.alert button.close::after,
.alert button.close::before {
  content: '';
  width: 15px;
  height: 2px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
}

.alert button.close::before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.alert button.close::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.modal-content .modal-header button.close {
  z-index: 2;
}

.nav-item .app-button {
  font-weight: 700 !important;
  background-image: linear-gradient(286.49deg, #3d0f65 -8.45%, #140237 106%) !important;
  background-color: #140237 !important;
}

.btn.bold {
  font-weight: 700 !important;
}

.nav-item .app-button:focus {
  background-image: linear-gradient(286.49deg, #3d0f65 -8.45%, #140237 106%) !important;
  background-color: #140237 !important;
}

@media screen and (max-width: 1024px) {
  .song__title {
    font-size: 25px;
  }

  .song__artist {
    font-size: 18px;
  }
}

@media screen and (max-width: 767px) {
  .song__title {
    font-size: 18px;
  }

  .song__artist {
    font-size: 16px;
  }

  .bar {
    width: 100%;
  }

  .bar__progress {
    margin: 0 15px;
  }
}
