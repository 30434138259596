.landing-container {
  width: 100%;
  height: 100%;
  max-width: 1236px;
  margin: 0 auto;
  padding: 0 24px;
  flex: auto;
  // overflow: hidden;
  &--big {
    max-width: 1436px;
  }

  &--small {
    padding: 0 16px;
    max-width: 1220px;
  }
}

.landing-btn {
  width: 100%;
  max-width: 320px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  font-size: 18px;
  letter-spacing: 0.03em;
  font-weight: 600 !important;
  margin: 0;
  box-shadow: 0px 11.6618px 26.504px rgba(252, 86, 54, 0.2);
  text-transform: capitalize;

  & + & {
    margin-top: 26px;
  }

  &.secondary {
    background: none !important;
    background-color: #ffffff !important;
    box-shadow: 0px 13.9941px 31.8048px rgba(255, 255, 255, 0.2);
    color: #1f0644;
  }

  &__icon {
    font-size: 1px;
    margin-left: 4px;
  }
}

.calendly-popup {
  background-color: #fff;
}

.title-gradient {
  background-image: linear-gradient(286.49deg, #3d0f65 -8.45%, #140237 106%);
  color: #140237;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section {
  padding: 65px 0;
}

.landing-title {
  font-size: 38px;
  line-height: 1.2;
  letter-spacing: 0.03em;
  font-weight: 700;
}

.landing-title-small {
  font-size: 28px;
  line-height: 1.2;
  font-weight: 700;
}

.landing-subtitle {
  font-family: Pacifico;
  font-size: 26px;
  line-height: 1.2;
  letter-spacing: 0.03em;
  color: #ce5783 !important;

  &--small {
    font-size: 20px;
  }
}

@media (max-width: 991px) {
  .landing-subtitle {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .section {
    padding: 40px 0;
  }

  .landing-title {
    font-size: 26px;
  }

  .landing-subtitle {
    font-size: 21px;

    &--small {
      font-size: 18px;
    }
  }

  .landing-title-small {
    font-size: 24px;
  }
}
