.unlock-btn {
  width: 100%;
  max-width: 231px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto 0 0 0;
  min-height: 40px;

  &--big {
    max-width: 100%;

    .unlock-btn__icon {
      width: 25px;
      height: 20px;
      margin-right: 4px !important;
    }

    span.unlock-btn__text {
      font-size: 14px;
      color: #fff !important;
    }
  }

  &.unlock-btn--inline {
    display: inline;
    white-space: normal;
    height: auto;
    font-size: 12px;
    max-height: 58px;

    .unlock-btn__icon {
      display: inline-flex !important;
    }
  }

  &__icon {
    width: 20px;
    height: 16px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin-right: 8px !important;

    svg {
      max-width: 100%;
      max-height: 100%;
      transform: scale(1) !important;
    }
  }

  &__text {
    font-weight: 600 !important;
    color: #ffffff;
  }
}

@media (max-width: 1350px) {
  .unlock-btn {
    &--big {
      .unlock-btn__icon {
        width: 18px;
        height: 14px;
      }
    }
    &__icon {
      width: 18px;
      height: 14px;
    }

    &__text {
      font-size: 12px;
    }
  }
}
