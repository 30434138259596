@import url(https://fonts.googleapis.com/css?family=Pacifico);

.banner-container {
  width         : 100%;
  background    : linear-gradient(286.49deg, #3d0f65 -8.45%, #140237 106%);
  min-height    : calc((var(--vh, 1vh) * 100) - 197px);
  display       : flex;
  flex-direction: column;
  padding-top   : 52px !important;
  position      : relative;
}

.banner-button {
  padding        : 4px;
  display        : flex;
  align-items    : center;
  justify-content: center;
  font-size      : 16px;
  min-height     : 44px;
  width          : 100%;
  max-width      : 168px;
  margin         : 0 auto;
}

.banner-button a:disabled {
  background: linear-gradient(273.5deg, #ee7734 -12.6%, #ef3616 107.81%);
  opacity   : 0.5;
  box-shadow: 0px 11px 25px rgba(252, 86, 54, 0.2);
}

.arrow-right-white {
  position     : relative;
  padding-right: 21px;
}

.arrow-right-white::after {
  content            : '';
  position           : absolute;
  top                : 50%;
  right              : 0;
  transform          : translateY(-50%);
  background-image   : url("data:image/svg+xml,%3Csvg width='17' height='19' viewBox='0 0 17 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.625 9.5L11.2793 8.88204L11.8629 9.5L11.2793 10.118L10.625 9.5ZM7.02931 4.38204L11.2793 8.88204L9.97069 10.118L5.72069 5.61796L7.02931 4.38204ZM11.2793 10.118L7.02931 14.618L5.72069 13.382L9.97069 8.88204L11.2793 10.118Z' fill='white'/%3E%3C/svg%3E%0A");
  background-repeat  : no-repeat;
  background-position: center;
  width              : 17px;
  height             : 19px;
}

.animatated {
  display: inline-block;
}

.animatated2 {
  display: block;
}

@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

#learning-partner {
  white-space: nowrap;
  width      : 0;
  animation  : typing 1s steps(19, end) forwards;
  overflow   : hidden;
  opacity    : 0.6;
  margin     : 0;
}

.banner-container .banner-para {
  font-family: Open Sans;
  font-size  : 16px;
  line-height: 1.4;
  text-align : center;
  width      : 100%;
  max-width  : 343px;
  color      : #e2d6ec;
  margin     : 0 auto 26px;
}

.banner-container .banner-para span {
  font-weight: 600;
}

.banner-title {
  font-weight   : 600;
  font-size     : 24px;
  line-height   : 1.4;
  color         : #FBFAFF !important;
  text-align    : center;
  margin        : 0 auto 12px;
  padding-bottom: 22px;
  width         : 100%;
  max-width     : 343px;
  position      : relative;
}

.banner-title::after {
  content         : '';
  position        : absolute;
  bottom          : 0;
  left            : 50%;
  transform       : translate(-50%, -50%);
  width           : 120px;
  height          : 2px;
  background-color: #CE5783;
  opacity         : 0.6;

}

.banner-title span {
  white-space: nowrap;
}

@keyframes banner-line {
  0% {
    width: 1px;
  }

  100% {
    width: 151px;
  }
}

#banner-line {
  height                   : 2;
  width                    : 150px;
  border                   : 2px solid #bd587e;
  background-color         : #bd587e;
  opacity                  : 0.6;
  animation-name           : banner-line;
  animation-duration       : 3s;
  animation-iteration-count: infinite;
  margin-top               : -2rem;
  margin-bottom            : 2rem;
}



.band-score-section .improve-image {
  width       : 560px;
  aspect-ratio: 16/9;
  margin-top  : 3rem;
}

@keyframes usercard {
  0% {
    opacity: 0.4;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    opacity: 1;
  }
}

.user-card {
  position          : absolute;
  width             : 225px;
  height            : 142px;
  right             : 500px;
  top               : 20%;
  animation-name    : usercard;
  animation-duration: 0.5s;
  transition        : 0.5s;

}

.user-card:hover {
  transform: translateX(10%) scale(1.2);
}

.user-wrap {
  position      : relative;
  width         : 100%;
  height        : 100%;
  display       : flex;
  flex-direction: column;
  padding       : 14px 30px 12px 32px;

}

.user-wrap::after {
  content        : '';
  position       : absolute;
  top            : 0;
  left           : 0;
  width          : 100%;
  height         : 100%;
  background     : rgba(255, 255, 255, 0.55);
  backdrop-filter: blur(15px);
  border-radius  : 16px;
  z-index        : 0;
}

.user-card p {
  font-family   : Open Sans;
  font-style    : normal;
  font-weight   : normal;
  font-size     : 14px !important;
  line-height   : 1.71;
  letter-spacing: 0.02em;
  color         : #3d0f65;
  margin        : 0;
  margin-bottom : 5px;
  z-index       : 1;
  height        : 100%;
  flex          : auto;
}

.user-card #name {
  font-family   : Open Sans;
  font-style    : normal;
  font-weight   : bold;
  font-size     : 14px;
  line-height   : 1.2;
  letter-spacing: 0.02em;
  color         : #3d0f65;
  margin-top    : auto;
  margin-bottom : 0;
  z-index       : 1;
}

.user-card h4 {
  font-family   : Open Sans;
  font-style    : normal;
  font-weight   : 300;
  font-size     : 12px;
  line-height   : 1.2;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  margin-bottom : 0;
  z-index       : 1;
}

.user-card img {
  position     : absolute;
  width        : 52px;
  height       : 52px;
  top          : 0;
  left         : 0;
  transform    : translate(-50%, -50%);
  border-radius: 100px;
  z-index      : 1;
}

@keyframes bandcard {
  0% {
    opacity: 0.4;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    opacity: 1;
  }
}

.band-card {
  position          : absolute;
  width             : 143px;
  height            : 84px;
  left              : 175px;
  right             : auto;
  top               : 43px;
  background        : rgba(255, 255, 255, 0.55);
  backdrop-filter   : blur(15px);
  border-radius     : 10px;
  animation-name    : bandcard;
  animation-duration: 0.5s;
  transition        : 0.5s;
  padding           : 0.5rem 1rem;
}

.band-card:hover {
  transform: scale(1.2);
}

.band-card p {
  color         : #3d0f65;
  font-family   : Open Sans;
  font-size     : 12px !important;
  line-height   : 1.2;
  letter-spacing: 0.03em;
  margin        : 0 0 4px 0;
}

.band-card h2 {
  font-weight: 600;
  font-size  : 25px;
  line-height: 1.4;
  color      : #3d0f65;
  margin     : 0;
}

.band-card img {
  margin-bottom: 2rem;
  margin-left  : 1rem;
  margin-top   : -1.5rem;
}

.title-gradient {
  background-image       : linear-gradient(286.49deg, #3D0F65 -8.45%, #140237 106%);
  color                  : #140237;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.band-arrow-container {
  width : 100px;
  height: 50px;
}

.band-arrow {
  width                     : 40px;
  height                    : 20px;
  border-bottom             : 1px solid white;
  border-right              : 1px solid white;
  border-bottom-right-radius: 50px;
}

.banner-container .banner-content {
  flex  : auto;
  height: 100%;
}


.banner-container .first-column {
  padding: 0 1rem !important;
}


.banner-container .banner-info {
  min-height: 40vh;
}

.banner-container .band-score-section {
  height: 100%;
}

.banner-container .improve-image {
  height    : 100% !important;
  width     : 100% !important;
  margin-top: 0 !important;
}

.banner-container .improve-image picture {
  display        : flex;
  justify-content: center;
  height         : 100%;
  margin         : 0 -16px;

}

.banner-container .improve-image img {
  height    : 100%;
  width     : auto !important;
  max-width : 100%;
  object-fit: cover;
}

.banner-container .bandScoreCardNew {
  top   : auto;
  bottom: 20px;
  right : 4px;
}

.banner-container .balsa {
  position     : relative;
  padding-right: 28px;
}

.banner-container .balsa+.balsa {
  margin-top: 8px;
}

.banner-container .balsa .balsa-img {
  position       : absolute;
  right          : 0;
  top            : 50%;
  transform      : translateY(-50%);
  width          : 17px;
  height         : 20px;
  display        : flex;
  align-items    : center;
  justify-content: center;
}

.banner-container .balsa-img img {
  width       : 100%;
  max-height  : 100%;
  height      : auto;
  aspect-ratio: 0;
}

.banner-container .band-card {
  top  : 10px;
  right: 10px;
}

.animated-wrap {
  display        : flex;
  justify-content: center;
  margin         : 0 0 2px 0;
}

.banner-content__info {
  width         : 100%;
  height        : 100%;
  display       : flex;
  flex-direction: column;
}

.banner-list {
  display    : flex;
  align-items: center;
  margin     : 0 auto;
}

.banner-list__item {
  font-size  : 12px;
  line-height: 1.4;
  font-weight: 600;
  color      : #FFFFFF;
  position   : relative;
}

.banner-list__item+.banner-list__item {
  margin-left : 8px;
  padding-left: 17px;
}

.banner-list__item+.banner-list__item::before {
  content            : '';
  position           : absolute;
  width              : 9px;
  height             : 12px;
  top                : 50%;
  left               : 0;
  transform          : translateY(-50%);
  background-image   : url("data:image/svg+xml,%3Csvg width='9' height='12' viewBox='0 0 9 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.5 12L9 6L0.5 0V12Z' fill='%23EE7734'/%3E%3C/svg%3E");
  background-repeat  : no-repeat;
  background-position: center;
  background-size    : contain;
}


@media screen and (min-width: 300px) and (max-width: 399px) {

  #learning-partner {
    text-align: left;
  }

  @keyframes banner-line {
    0% {
      width: 1px;
    }

    100% {
      width: 151px;
    }
  }

  #banner-line {
    height: 2;
    width : 100px;
  }

  .band-score-section .improve-image {
    width      : 307px;
    height     : 289px;
    margin-top : 6rem;
    margin-left: 0.5rem;
  }

  .band-card {
    left: 180px !important;
    top : 0px;
  }

  .user-card {
    top  : 70px;
    left : 15px;
    width: 150px;
  }

  .user-card p {
    width: 120px !important;
  }

  .user-card img {
    width : 30px;
    height: 30px;
  }

}

@media screen and (min-width: 300px) and (max-width: 499px) {
  .band-card {
    right: 20px;
    left : auto !important;
    top  : 75px;
  }

  .user-card {
    top   : 40px;
    left  : 18px;
    right : auto;
    width : 150px;
    height: 115px;
  }
}

@media screen and (min-width: 400px) and (max-width: 499px) {

  #learning-partner {
    text-align: left;
  }


  @keyframes banner-line {
    0% {
      width: 1px;
    }

    100% {
      width: 151px;
    }
  }

  #banner-line {
    height: 2;
    width : 100px;
  }

  .band-score-section .improve-image {
    width      : 307px;
    height     : 289px;
    margin-top : 2rem;
    margin-left: 0.5rem;
  }

  .user-card {
    width : 150px;
    height: 115px;
  }

  .user-card p {
    font-size  : 10px !important;
    width      : 120px !important;
    line-height: 14px;
  }

  .user-card #name {
    font-size: 11px !important;
  }

  .user-card img {
    width : 30px;
    height: 30px;
  }

  .banner-container .band-card {
    right: 10%;
  }
}

@media screen and (min-width: 500px) and (max-width: 575px) {
  #learning-partner {
    text-align: left;
  }

  @keyframes banner-line {
    0% {
      width: 1px;
    }

    100% {
      width: 151px;
    }
  }

  #banner-line {
    height: 2;
    width : 100px;
  }

  .band-score-section .improve-image {
    width      : 307px;
    height     : 289px;
    margin-top : 2rem;
    margin-left: 0.5rem;
  }

  .band-card {
    left: 250px !important;
    top : 0px;
  }

  .user-card {
    top   : 40px;
    left  : 15px;
    width : 150px;
    height: 115px;
  }

  .user-card p {
    font-size  : 10px !important;
    width      : 120px !important;
    line-height: 14px;
  }

  .user-card #name {
    font-size: 11px !important;
  }

  .user-card img {
    width : 30px;
    height: 30px;
  }
}

@media screen and (min-width: 300px) and (max-width: 577px) {
  .band-card {
    padding: 6px 14px 4px 8px;
    width  : 83px;
    height : 48px;
  }

  .band-card p {
    font-size: 7px !important;
  }

  .band-card h2 {
    font-size: 14px;
  }

  .band-card img {
    position : absolute;
    width    : 2rem;
    top      : 0;
    right    : 0;
    margin   : 0;
    transform: translateY(-90%);
  }
}

@media screen and (min-width: 300px) and (max-width: 767px) {

  .user-wrap {
    padding: 8px 20px;
  }

  .user-card {
    height: 103px;
  }

  .user-card p {
    font-size: 8px !important;
  }

  .user-card #name {
    font-size: 8px !important;
  }

  .user-card h4 {
    font-size: 7px !important;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {

  @keyframes banner-line {
    0% {
      width: 1px;
    }

    100% {
      width: 150px;
    }
  }

  #banner-line {
    width: 110px;
  }

  .band-score-section .improve-image {
    width       : 377px;
    aspect-ratio: 16/9;
    margin-top  : 2rem;
    margin-left : 4rem;
  }

  .band-card {
    left : auto;
    right: 75px;
    top  : 0px;
  }

  .user-card {
    top   : 50px;
    left  : 70px;
    width : 195px;
    height: 120px;
  }

  .user-card p {
    font-size: 11px !important;
    width    : 160px !important;

  }

  .user-card #name {
    font-size: 11px !important;
  }

  .user-card img {
    width : 30px;
    height: 30px;
  }

  .banner-container .balsa {
    padding-right: 0;
  }

  .banner-container .balsa .balsa-img {
    position : relative;
    right    : 0;
    top      : 0;
    transform: none;
    width    : 15px;
    height   : 15px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1100px) {

  @keyframes banner-line {
    0% {
      width: 1px;
    }

    100% {
      width: 150px;
    }
  }

  #banner-line {
    width: 110px;
  }

  .band-score-section .improve-image {
    width      : 398px;
    height     : 375px;
    margin-top : 8rem;
    margin-left: -4rem;
  }

  .band-card {
    left: 35px;
    top : 95px;
  }

  .user-card {
    width : 236px;
    height: 133px;
  }

  .user-card p {
    font-size: 11px !important;
    width    : 170px !important;
  }

  .user-card #name {
    font-size: 11px !important;
  }

  .user-card img {
    width : 30px;
    height: 30px;
  }

}

@media screen and (min-width: 1101px) and (max-width: 1280px) {

  @keyframes banner-line {
    0% {
      width: 1px;
    }

    100% {
      width: 220px;
    }
  }

  #banner-line {
    width: 150px;
  }

  .band-score-section .improve-image {
    width       : 480px;
    aspect-ratio: 16/9;
    margin-top  : 8rem;
    margin-left : -3.5rem;
  }

  .band-card {
    left: 80px;
    top : 115px;
  }

  .user-card {
    width : 226px;
    height: 155px;
  }

  .user-card p {
    font-size: 13px !important;
    width    : 185px !important;
  }

  .user-card #name {
    font-size    : 14px !important;
    margin-bottom: 3px;
    line-height  : 23px;
  }

  .user-card img {
    width : 37px;
    height: 37px;
  }


}

@media (min-width: 768px) {

  .banner-container {
    min-height: calc((var(--vh, 1vh) * 100) - 159px);
  }

  .banner-container .improve-image img {
    position : absolute;
    bottom   : 0;
    right    : 0;
    max-width: 60vw;
    transform: translateX(18%);
    height   : auto;
    z-index  : 0;
  }

  .animated-wrap {
    justify-content: flex-start;
    margin-bottom  : 4px;
  }

  .banner-container .improve-image {
    margin: 0 !important;
  }

  .user-card {
    left  : -40%;
    right : auto;
    top   : auto;
    bottom: 51px;
    width : auto;
  }

  .user-wrap {
    padding: 12px;
  }

  .banner-container .first-column {
    padding-top: 32px;
  }

  .banner-container .bandScoreCardNew {
    bottom: 65px;
  }

  .banner-title {
    max-width     : 100%;
    text-align    : left;
    font-size     : 34px;
    margin-bottom : 30px;
    padding-bottom: 10px;
    margin-bottom : 20px;
  }

  .banner-title::after {
    left     : 0;
    transform: translate(0);
    width    : 160px;
  }

  .banner-title span {
    color: #EE7734;
  }

  .banner-container .banner-para {
    font-size : 16px;
    max-width : 352px;
    text-align: left;
    margin    : 0 0 20px 0;
  }

  .banner-button {
    margin: 0;
  }

  .banner-list {
    margin: 0 0 20px 0;
  }

}

@media (min-width: 991px) {
  .banner-container .first-column {
    padding-top: 60px;
  }

  .user-card {
    left: -30%;
  }

  .banner-container {
    min-height: calc((var(--vh, 1vh) * 100) - 110px);
  }
}

@media (min-width: 1024px) {
  .user-card {
    left  : -20%;
    bottom: 15px;
    top   : auto;
  }

  .banner-content__info {
    height   : auto;
    position : relative;
    top      : 50%;
    transform: translateY(-100%);
    z-index  : 3;
    width    : 100%;
    max-width: 560px;
  }
}

@media (min-width: 1100px) {

  .banner-container .improve-image img {
    right     : 15%;
    max-width : 53vw;
    transform : translateX(0);
    height    : auto;
    max-height: 100%;
  }

  .banner-container .band-card {
    right: auto;
    top  : 15%;
    left : 15px;
  }

  .banner-container .first-column {
    display       : flex;
    align-items   : center;
    padding-bottom: 52px !important;
  }

  .user-card {
    bottom: 63px;
  }

  .user-wrap {
    padding: 12px 30px;
  }

  .banner-container .bandScoreCardNew {
    right : 40px;
    bottom: 115px;
  }

  .banner-content__info {
    top      : 0;
    transform: translate(0);
  }
}


@media (min-width: 1200px) {
  .banner-title {
    font-size: 48px;
  }

  .user-card {
    bottom: 63px;
  }

  .banner-container .banner-para {
    font-size: 21px;
    max-width: 479px;
  }

  .banner-list {
    margin-bottom: 40px;
  }

  .banner-list__item {
    font-size: 16px;
  }

  .banner-list__item+.banner-list__item {
    margin-left : 10px;
    padding-left: 19px;
  }
}


@media (min-width: 1281px) {

  @keyframes banner-line {
    0% {
      width: 1px;
    }

    100% {
      width: 241px;
    }
  }

  #banner-line {
    height: 3;
    width : 180px;
    border: 3px solid #bd587e;
  }


  .band-score-section .improve-image {
    width       : 100%;
    aspect-ratio: 16/9;
  }

  .user-card {
    width : 240px;
    height: 160px;
    right : 510px;
  }

  .user-card #name {
    font-size  : 15px;
    line-height: 20px;
  }

  .user-card h4 {
    font-weight: 320;
    font-size  : 14.003px;
  }

  .band-card {
    left: 145px;
    top : 12px;
  }

  .band-arrow-container {
    width : 78px;
    height: 37px;
  }
}

@media (min-width: 1600px) {
  .banner-container {
    height: 80vh;
    height: auto;
  }

  @keyframes banner-line {
    0% {
      width: 1px;
    }

    100% {
      width: 251px;
    }
  }

  #banner-line {
    height: 3;
    width : 200px;
    border: 3px solid #bd587e;
  }


  .band-score-section .improve-image {
    width       : 100%;
    aspect-ratio: 16/9;
    margin-top  : 0;
  }

  .user-card {
    width : 265px;
    height: 182px;
    left  : 0;
  }

  .user-card p {
    font-size  : 18px !important;
    line-height: 20px;
  }

  .user-card #name {
    font-size  : 20px;
    line-height: 20px;
  }

  .user-card h4 {
    font-weight: 400;
    font-size  : 18.003px;
  }

  .user-card img {
    width : 55px;
    height: 55px;
  }

  .band-arrow-container {
    width : 100px;
    height: 50px;
  }
}

@media (min-width: 1705px) {
  .banner-container {
    height: auto;
  }

  @keyframes banner-line {
    0% {
      width: 1px;
    }

    100% {
      width: 201px;
    }
  }

  #banner-line {
    height: 3;
    width : 200px;
    border: 3px solid #bd587e;
  }

  .band-score-section .improve-image {
    width       : 100%;
    aspect-ratio: 16/9;
    margin-top  : 0;
  }

  .user-card img {
    width : 55px;
    height: 55px;
  }

  .band-arrow-container {
    width : 100px;
    height: 50px;
  }

}

@media (min-width: 1920px) {
  .banner-container .improve-image img {
    right     : 0;
    max-width : 100vw;
    transform : translateX(0);
    height    : 80%;
    max-height: 100%;
  }

  .banner-container {
    height: auto;
  }

  @keyframes banner-line {
    0% {
      width: 1px;
    }

    100% {
      width: 271px;
    }
  }

  #banner-line {
    height: 3;
    width : 220px;
    border: 3px solid #bd587e;
  }

  .band-score-section .improve-image {
    width       : 100%;
    aspect-ratio: 16/9;
    margin-top  : 0rem;
    margin-left : -5rem;
    bottom      : 0;
  }

  .band-arrow-container {
    width : 100px;
    height: 50px;
  }

}