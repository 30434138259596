.instan-evaluation {
  position : absolute;
  width    : 137px;
  height   : 28px;
  top      : 0;
  left     : 50%;
  transform: translate(-50%, -50%);
}

.instan-evaluation span {
  position       : relative;
  width          : 100%;
  height         : 100%;
  white-space    : nowrap;
  display        : flex;
  align-items    : center;
  justify-content: center;
  text-align     : center;
  font-size      : 12px !important;
  font-weight    : 600;
  color          : #3d0f65;
  padding        : 4px;
}

.instan-evaluation span::after {
  content        : '';
  position       : absolute;
  background     : rgba(255, 255, 255, 0.65);
  backdrop-filter: blur(15px);
  border-radius  : 20px;
  top            : 50%;
  left           : 50%;
  transform      : translate(-50%, -50%);
  width          : 100%;
  height         : 100%;
  z-index        : -1;
}

@keyframes bandscore {
  0% {
    opacity: 0.2;
  }

  50% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}

.bandScoreCardNew {
  position          : absolute;
  width             : 283px;
  height            : auto;
  padding           : 0.875rem 1.5rem;
  right             : 40px;
  top               : 351px;
  background        : rgba(255, 255, 255, 0.55);
  border-radius     : 16px;
  animation-name    : bandscore;
  animation-duration: 0.5s;
  transition        : 0.5s;
}

.bandScoreCardNew:hover {
  transform: translateX(-20%) scale(1.2);
}

.bandScoreCardNew.bandScoreCardNew--app {
  top          : auto;
  bottom       : 20px;
  right        : 0;
  left         : auto;
  border-radius: 8px;
  padding      : 5px 10px 8px;
  width        : 184px;
}

.balsa {
  display        : flex;
  flex-direction : column;
  justify-content: flex-start;
  align-items    : flex-start;
}

.balsa .progressbar {
  width       : calc(100% - 30px);
  flex        : auto;
  height      : 6px;
  margin-right: 10px;
}

.balsa .progress-wrap {
  display        : flex;
  justify-content: space-between;
  align-items    : center;
  width          : 100%;
}

.balsa--app+.balsa--app {
  margin-top: 5px;
}

.balsa.balsa--app .progress-wrap {
  margin: 0;
}

.balsa-progress {
  position    : relative;
  width       : 100%;
  height      : 6px;
  margin-right: 10px;
}

.balsa-progress::before {
  content         : '';
  position        : absolute;
  background-image: linear-gradient(286.49deg, #3D0F65 -8.45%, #140237 106%);
  opacity         : 0.1;
  border-radius   : 4px;
  height          : 100%;
  width           : 100%;
}

.balsa-progress span {
  position     : absolute;
  top          : 0;
  left         : 0;
  height       : 100%;
  border-radius: 4px;
}

.progress-wrap img {
  flex: none;
}

.balsa-img {
  flex           : none;
  display        : flex;
  align-items    : center;
  justify-content: center;
  height         : 20px;
  width          : 20px;
}

.img {
  width       : 100%;
  object-fit  : cover;
  aspect-ratio: 0 !important;
  flex        : auto !important;
}

.progress-inner {
  display       : flex;
  flex-direction: column;
  width         : 100%;
  margin-right  : 8px;
}


.band-score-icon {
  position : absolute;
  top      : 0;
  left     : 0;
  transform: translate(-50%, -50%);
}

@media screen and (min-width: 300px) and (max-width: 399px) {
  .bandScoreCardNew {
    left   : auto;
    right  : 15px;
    top    : 220px;
    padding: 9px 1rem;
  }

  .bandScoreCardNew span {
    font-size: 0.6rem;
  }

  .balsa .progressbar {
    height: 5px;
  }

  .balsa-img {
    width : 15px;
    height: 15px;
  }
}

@media screen and (min-width: 300px) and (max-width: 499px) {
  .bandScoreCardNew {
    width: 184px;
  }

  .bandScoreCardNew:hover {
    transform: translateX(-10%) scale(1.2);
  }
}

@media screen and (min-width: 400px) and (max-width: 499px) {
  .bandScoreCardNew {
    right  : 15px;
    top    : 155px;
    padding: 9px 1rem;
  }

  .bandScoreCardNew span {
    font-size: 0.6rem;
  }

  .balsa .progressbar {
    height: 5px;
  }

  .balsa-img {
    width : 15px;
    height: 15px;
  }
}

@media screen and (min-width: 500px) and (max-width: 575px) {
  .bandScoreCardNew {
    right  : 15px;
    top    : 160px;
    padding: 9px 1rem;
  }

  .bandScoreCardNew span {
    font-size: 0.6rem;
  }

  .balsa .progressbar {
    height: 5px;
  }

  .balsa-img {
    width : 15px;
    height: 15px;
  }

  .instan-evaluation {
    font-size: 8px;
    width    : auto;
    height   : auto;
    padding  : 2px 4px;
  }
}

@media screen and (min-width: 300px) and (max-width: 767px) {
  .instan-evaluation {
    width : auto;
    height: auto;
  }

  .instan-evaluation span {
    font-size: 8px !important;
    padding  : 4px 8px;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .bandScoreCardNew {
    right  : auto;
    left   : 272px;
    bottom : 20px;
    top    : auto;
    padding: 9px 1rem;
  }

  .bandScoreCardNew.bandScoreCardNew--app {}

  .bandScoreCardNew span {
    font-size: 0.8rem;
  }

  .balsa .progressbar {
    height: 6px;
  }

  .balsa-img {
    width : 15px;
    height: 15px;
  }
}

@media (min-width: 768px) {
  .bandScoreCardNew.bandScoreCardNew--app {
    padding-top: 16px;
  }

  .bandScoreCardNew--app .instan-evaluation {
    width    : 137px;
    font-size: 10px;
    padding  : 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 1100px) {
  .bandScoreCardNew {
    right  : 30px;
    top    : 300px;
    padding: 0.875rem 1.5rem;
  }

  .bandScoreCardNew span {
    font-size: 0.6rem;
  }

  .balsa .progressbar {
    height: 7px;
  }

  .balsa-img {
    height: 15px;
    width : 15px;
  }
}

@media screen and (min-width: 1101px) and (max-width: 1280px) {
  .bandScoreCardNew {
    right  : 30px;
    top    : 300px;
    padding: 0.875rem 1.5rem;
  }

  .bandScoreCardNew span {
    font-size: 0.7rem;
  }

  .balsa .progressbar {
    height: 8px;
  }

  .balsa span {
    font-size: 12px;
  }

  .balsa-img {
    width : 20px;
    height: 20px;
  }
}

@media (min-width: 768px) {
  .instan-evaluation {
    width: 166px;
  }
}

@media (min-width: 1281px) {
  .bandScoreCardNew {
    padding: 0.875rem 1.5rem;
    right  : 30px;
    top    : 310px;
    left   : auto;
  }

  .balsa .progressbar {
    height: 10px;
  }

  .balsa-img {
    width : 20px;
    height: 20px;
  }

  .img {
    max-width : 100%;
    max-height: 100%;
    width     : auto;
    height    : auto;
    object-fit: cover;
  }

  .balsa span {
    font-size: 14px;
  }

  .instan-evaluation {
    font-size: 12px;
  }
}

@media (min-width: 1600px) {
  .bandScoreCardNew {
    padding: 0.875rem 1.5rem;
    top    : 241px;
    left   : 430px;
  }

  .balsa .progressbar {
    height: 10px;
  }

  .balsa span {
    font-size: 16px;
  }

  .balsa-img {
    height: 30px;
    width : 30px;
  }

  .instan-evaluation {
    font-size: 16px;
    padding  : 4px 1rem;
  }
}

@media (min-width: 1705px) {
  .bandScoreCardNew {
    padding: 0.875rem 1.5rem;
    top    : 271px;
    right  : 0px;
    left   : 490px;
  }

  .balsa .progressbar {
    height: 10px;
  }

  .balsa span {
    font-size: 16px;
  }

  .balsa-img {
    height: 30px;
    width : 30px;
  }

  .instan-evaluation {
    font-size: 16px;
    padding  : 4px 1rem;
  }
}

@media (min-width: 1920px) {
  .bandScoreCardNew {
    padding: 0.875rem 1.5rem;
  }

  .balsa .progressbar {
    height: 12px;
  }

  .balsa span {
    font-size: 16px;
  }

  .balsa-img {
    height: 30px;
    width : 30px;
  }

  .instan-evaluation {
    font-size: 16px;
    padding  : 4px 1rem;
  }
}