.footer_error {
  position: absolute;
  width: 100%;
  height: 92px;
  background: #f68d4d;
  bottom: 0;
  @media (min-width: 992px) {
    height: 131px;
  }
}
