.statement-section {
  padding-top: 104px !important;
}

.statement-wrap {
  display: flex;
  align-items: center;
  position: relative;
  min-height: 334px;
}

.statement-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 -30px;
}

.statement-img {
  width: 50%;
  max-width: 600px;
  padding: 0 30px;

  picture {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    width: 100%;
    object-fit: cover;
  }
}

.map-phot {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 48vw;
  height: auto;
  max-height: 334px;
  border-radius: 0 160px 160px 0;
  overflow: hidden;
  background-image: linear-gradient(286.49deg, #3d0f65 -8.45%, #140237 106%);

  img {
    width: 100%;
    display: block;
    height: auto;
  }
}

.statement-info {
  width: 50%;
  max-width: 560px;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  padding: 0 30px;

  &__subtitle {
    margin-bottom: 8px;
  }

  &__title {
    margin-bottom: 17px;
  }

  &__text {
    font-size: 18px;
    line-height: 1.6;
    letter-spacing: 0.02em;
    font-weight: 600;
    margin: 0;

    & + & {
      margin-top: 10px;
    }

    span {
      font-weight: 700;
    }
  }
}

@media (max-width: 767px) {
  .statement-section {
    padding-top: 95px !important;
  }

  .statement-wrap {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  .map-phot {
    position: relative;
    transform: translate(0);
    top: 0;
    width: 90vw;
  }

  .statement-info {
    width: 100%;
    max-width: 100%;
    margin-left: 0;
    margin-bottom: 40px;

    &__title {
      margin-bottom: 16px;
    }
  }

  .statement-inner {
    flex-direction: column;
  }

  .statement-img {
    order: 1;
    padding: 0;
    width: 70%;
    max-width: 100%;
  }
}

@media (max-width: 600px) {
  .statement-section {
    padding-top: 190px !important;
  }

  .statement-img {
    width: 100%;
  }
}
