.banner-benefits {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, calc(100% - 12px));
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 20px 12px;
  width: 100%;
  max-width: 281px;

  &__item {
    display: flex;
    align-items: center;
  }

  &__item + .banner-benefits__item {
    margin-top: 15px;
  }

  &__icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    flex: none;
  }

  &__icon svg {
    width: 100%;
    height: 100%;
  }

  &__text {
    font-size: 12px;
    line-height: 1.4;
    color: #000000;
  }
}

@media (min-width: 600px) {
  .banner-benefits {
    padding: 8px 2px;
    flex-direction: row;
    border-radius: 8px;
    max-width: 95%;

    &__item {
      width: 25%;
      padding: 0 6px;
    }

    &__item + .banner-benefits__item {
      margin-top: 0;
    }
  }
}

@media (min-width: 768px) {
  .banner-benefits {
    padding: 16px 28px;
    width: 776px;
    max-width: 95%;

    &__item {
      padding: 0 8px;
    }
  }
}

@media (min-width: 1100px) {
  .banner-benefits {
    transform: translate(-50%, 50%);
  }
}
