.error_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 76px;
  @media (min-width: 992px) {
    @media (max-height: 755px) {
      margin-top: 40px;
    }

    @media (max-height: 710px) {
      margin-top: 20px;
    }
  }
}

.error_title {
  color: #000 !important;
  font-family: Open Sans;
  font-size: 28px;
  text-align: start;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0;
  width: 236px;
  @media (min-width: 992px) {
    width: unset;

    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

.error_desc {
  color: #000 !important	;
  font-family: Open Sans;
  font-size: 16px;
  text-align: start;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 236px;
  @media (min-width: 992px) {
    width: unset;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
.button_block {
  display: flex;
  justify-content: center;
}

.refresh_button {
  display: flex;
  padding: 9px 21px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 11px;
  background: #ff8139;
  border: none;

  color: #fff;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @media (min-width: 992px) {
    padding: 13px 31px;

    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
