.term__button {
  color: #fc8150;
  background: transparent;
  border: none;
  outline: none;
  margin-top: 20px;
  margin-left: 20px;
  margin-bottom: 10px;
}

.term__container {
  margin-left: 30px;

  @media (max-width: 507px) {
    margin-left: 15px;
  }
}

.list__term {
  color: black !important;
  li {
    color: black !important;
  }
}
