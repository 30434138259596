UserMessage {
    font-family: Open Sans;
    text-align: center;
    min-height: 100px;

  }
  @keyframes blink {
    from {
      opacity: 0%;
    }
    to {
      opacity: 100%;
    }
  }
  .type-writer::after {
    content: "|";
    animation: typing 1s steps(19, end) forwards;
  }
