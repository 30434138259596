.sidebar_card {
  width: calc(100% - 20px);
  padding: 10px;
  background: linear-gradient(286.49deg, #3d0f65 -8.45%, #140237 106%);
  border-radius: 12px;
  left: 50%;
  transform: translateX(-50%);
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.sidebar_card h2 {
  width: 128px;
  font-family: Open Sans;
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #e2d6ec !important;
}
.sidebar_card button {
  font-family: Open Sans;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  background: linear-gradient(273.5deg, #ee7734 -12.6%, #ef3616 107.81%);
  border-radius: 4px;
  color: #000000;
  flex: none;
  margin: 4px;
  outline: none;
  border: none;
  padding: 0.8rem 1.2rem;
}
.sidebar {
  width: 22%;
  width: 300px;
  height: 100vh;

  box-shadow: none;
  background: inherit !important;
  top: 0;
  z-index: 1;

  position: sticky;
  position: -webkit-sticky;
  background-size: cover;
  background-position: center center;
  .sidebar-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    height: 100%;

    overflow: auto;

    padding: 0 14px;
    .kandor_select {
      margin: 0 auto;

      display: flex;
      align-items: center;
      justify-content: center;
      background-position: right 0.5rem center;
      width: 159px;
      height: 59px;
      color: white;
      background: #4f287a;
      border: 1px solid rgba(219, 219, 219, 0.2);
      box-shadow: 0px 8px 24px rgba(255, 255, 255, 0.09);
      border-radius: 20px;

      font-weight: 600;
      font-size: 20px;
      line-height: 1.4;

      @media (max-width: 1199px) {
        opacity: 0;
      }
    }

    .side__bar-action {
      .side__bar-link {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.3;
        color: #828282;
        cursor: pointer;
        padding-left: 15px;
      }
    }

    .navbar-form {
      border: none;
    }

    > .nav,
    .user .info {
      [data-toggle='collapse'] ~ div > ul > li > a {
        span {
          display: inline-block;
          @extend .animation-transition-general;
        }

        .sidebar-normal {
          margin: 0;
          transform: translateX(0px);
          opacity: 1;
          white-space: nowrap;
          display: block;
          z-index: 1;
          margin-left: 15px;
          text-transform: capitalize;
          font-size: 16px;
          line-height: 1.3;
          font-weight: 600;
        }

        .sidebar-mini-icon {
          text-transform: uppercase;
          width: 34px;
          margin-right: 10px;
          margin-left: 0px;
          font-size: 18px;
          text-align: center;
          line-height: 25px;
          position: relative;
          float: left;
          z-index: 1;
          display: inherit;
          line-height: 24px;
          color: $opacity-8;
        }

        i {
          font-size: 18px;
          line-height: 20px;
          width: 26px;
        }
      }
    }

    [data-toggle='collapse'] ~ div > ul > li:hover > a {
      .sidebar-mini-icon,
      .sidebar-normal {
        color: $white;
      }
    }

    .nav [data-toggle='collapse'] ~ div > ul > li > a .sidebar-mini-icon {
      opacity: 0;
    }
  }

  .navbar-minimize {
    position: absolute;
    right: 20px;
    top: 2px;
    opacity: 1;
    @extend .animation-transition-general;
  }
  .logo-tim {
    border-radius: 50%;
    border: 1px solid #333;
    display: block;
    height: 61px;
    width: 61px;
    float: left;
    overflow: hidden;

    img {
      width: 60px;
      height: 60px;
    }
  }

  .nav {
    display: block;

    .caret {
      top: 4px;
      position: absolute;
      right: 10px;
    }

    .nav__link {
      padding-top: 11px;
      padding-left: 15px;
      padding-bottom: 9px;
      display: flex;
      align-items: center;
      margin: 0;
      margin-bottom: 6px;
    }

    li {
      > a {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;

        font-weight: 600;
        font-size: 16px;
        line-height: 1.3;
        cursor: pointer;

        color: #c4c4c4;
      }

      &:hover:not(.active) > a,
      &:focus:not(.active) > a {
        p,
        i {
          color: $white;
        }
      }

      &:hover:not(.active) > a i,
      &:focus:not(.active) > a i {
        color: $white;
      }

      &.active > a:not([data-toggle='collapse']) {
        background: rgba(255, 255, 255, 0.1);
        border-radius: 7px;
        font-weight: 700;
        font-size: 16px !important;
        line-height: 1.3 !important;
        color: #ff8139;
        i,
        p {
          color: white;
        }
      }

      &.active > a[data-toggle='collapse'] {
        background: transparent;
        box-shadow: none;
        color: $white;

        i {
          color: $white;
        }

        & + div .nav .active a {
          box-shadow: none;
          .sidebar-mini-icon {
            color: $white;
            font-weight: $font-weight-normal;
          }

          &:before {
            content: ' ';
            position: absolute;
            height: 6px;
            width: 6px;
            top: 17px;
            left: -4px;
            background: $white;
            border-radius: 50%;
          }
        }
        &:before {
          content: ' ';
          position: absolute;
          height: 6px;
          width: 6px;
          top: 22px;
          left: -4px;
          background: rgba($white, 0.6);
          border-radius: 50%;
        }
      }
    }

    p {
      margin: 0;
      line-height: 30px;
      position: relative;
      display: block;
      height: auto;
      white-space: nowrap;
      @extend .animation-transition-general;
    }

    i {
      font-size: 20px;
      float: left;
      margin-right: 12px;
      line-height: 30px;
      width: 34px;
      text-align: center;
      color: $opacity-8;
      position: relative;
    }
  }

  .sidebar-background {
    position: absolute;
    background-color: chartreuse;
    z-index: 1;
    height: 100%;
    width: $full-width;
    display: block;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center center;

    &:after {
      position: absolute;
      z-index: 3;
      width: $full-width;
      height: 100%;
      content: '';
      display: block;
      background: #ffffff;
      opacity: 1;
    }
  }

  .logo {
    position: relative;
    padding: $padding-base-vertical $padding-base-horizontal 1rem;
    z-index: 4;

    a.logo-mini,
    a.logo-normal {
      @extend .animation-transition-general;
    }

    a.logo-mini {
      opacity: 1;
      float: left;
      width: 34px;
      text-align: center;
      margin-left: 10px;
      margin-right: 12px;
    }

    a.logo-normal {
      display: block;
      opacity: 1;
      @include transform-translate-x(0px);
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 15px;
      height: 1px;
      width: calc(100% - 30px);
      background: $opacity-5;
    }

    p {
      float: left;
      font-size: 20px;
      margin: 10px 10px;
      color: $white;
      line-height: 20px;
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    }

    .simple-text {
      text-transform: uppercase;
      padding: $padding-base-vertical 0;
      display: block;
      white-space: nowrap;
      font-size: 20px;
      color: $white;
      text-decoration: none;
      font-weight: $font-weight-normal;
      line-height: 30px;
      overflow: hidden;
    }
  }

  .logo-tim {
    border-radius: 50%;
    border: 1px solid #333;
    display: block;
    height: 61px;
    width: 61px;
    float: left;
    overflow: hidden;

    img {
      width: 60px;
      height: 60px;
    }
  }

  &[data='blue'] {
    @include linear-gradient($info-states, $info);
    &:before {
      border-bottom-color: $info;
    }
  }
  &[data='green'] {
    @include linear-gradient($success-states, $success);
    &:before {
      border-bottom-color: $success;
    }
  }
  &[data='orange'] {
    @include linear-gradient($warning-states, $warning);
    &:before {
      border-bottom-color: $warning;
    }
  }
  &[data='red'] {
    @include linear-gradient($danger-states, $danger);
    &:before {
      border-bottom-color: $danger;
    }
  }

  .user {
    padding-bottom: 20px;
    margin: 20px auto 0;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 15px;
      height: 1px;
      width: calc(100% - 30px);
      background: $opacity-5;
    }

    .photo {
      width: 34px;
      height: 34px;
      overflow: hidden;
      float: left;
      z-index: 5;
      margin-right: 10px;
      border-radius: 50%;
      margin-left: 23px;
      /* box-shadow: $box-shadow-raised; */
      @extend .animation-transition-general;

      img {
        width: $full-width;
      }
    }

    a {
      color: $white;
      text-decoration: none;
      padding: $padding-base-vertical 15px;
      white-space: nowrap;
      @extend .animation-transition-general;
    }

    .info {
      > a {
        display: block;
        line-height: 18px;

        > span {
          @extend .animation-transition-general;
          display: block;
          position: relative;
          opacity: 1;
        }
      }

      .caret {
        position: absolute;
        top: 8px;
        right: 18px;
      }
    }
  }

  &:before {
    content: '';
    position: absolute;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid $primary;
    top: -5px;
    left: 40px;
    transform: translate(-50%);
  }
}

.sidebar-googleplay {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px !important;
  width: 100%;
  min-height: 38px;
  color: #fff !important;

  &__icon {
    width: 10px;
    height: 10px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin-right: 4px;
    flex: none;

    svg {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__text {
    font-size: 10px;
  }
}

.visible-on-sidebar-regular {
  display: inline-block !important;
}
.visible-on-sidebar-mini {
  display: none !important;
}

.off-canvas-sidebar {
  .nav {
    > li > a,
    > li > a:hover {
      color: $white;
    }

    > li > a:focus {
      background: rgba(200, 200, 200, 0.2);
    }
  }
}

.main-panel {
  border: 20px;
  position: relative;
  float: right;
  margin-top: 16px;
  margin-right: 16px;
  margin-bottom: 16px;
  width: $full-width;
  // height: calc(100vh - 39px);
  background: #f6f6f6;

  @media (min-width: 768px) and (max-width: 1200px) {
    margin-top: 49px;
    margin-right: 0;
    margin-bottom: 0;
  }

  @media (max-width: 768px) {
    margin-top: 49px;
    margin-right: 0;
    padding-bottom: 63.5px;
  }

  @include transitions(0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));

  > .navbar {
    margin-bottom: 0;
  }

  .header {
    margin-bottom: 50px;
  }
}

.panel-header {
  height: $sidebar-width;
  padding-top: 80px;
  padding-bottom: 45px;
  background: #141e30; /* fallback for old browsers */
  background: -webkit-gradient(linear, left top, right top, from(#0c2646), color-stop(60%, #204065), to(#2a5788));
  background: linear-gradient(to right, #0c2646 0%, #204065 60%, #2a5788 100%);
  position: relative;
  overflow: hidden;

  .header {
    .title {
      color: $white;
    }
    .category {
      max-width: 600px;
      color: $opacity-5;
      margin: 0 auto;
      font-size: 18px;

      a {
        color: $white;
      }
    }
  }
}

.panel-header-sm {
  height: 135px;
}

.panel-header-lg {
  height: 380px;
}

@include media-breakpoint-down(lg) {
  .sidebar {
    position: fixed;
    display: block;
    top: 0;
    right: auto;
    left: 0;
    margin: 0;
    border-radius: 0;
    visibility: visible;
    overflow-y: hidden;
    padding: 0;
    @include transition($sidebar-transition);
    @include transform-translate-x(-368px);
  }

  .nav-open {
    .main-panel,
    .reading-wrap,
    .tests-wrap {
      overflow: hidden !important;
      filter: blur(20px);
      scrollbar-width: none;
      pointer-events: none;
      // -webkit-filter: blur(20px);
      // @include transform-translate-x($sidebar-width);
    }

    body,
    html {
      overflow: hidden !important;
    }

    .sidebar {
      @include transition($sidebar-transition);
      @include transform-translate-x(0px);
      &:before {
        content: unset;
      }
    }

    .menu-on-right {
      .main-panel {
        @include transform-translate-x(-$sidebar-width);
      }

      .navbar-collapse,
      .sidebar {
        @include transform-translate-x(0px);
      }

      .bodyClick {
        right: $sidebar-width;
        left: auto;
      }
    }
  }

  .menu-on-right {
    .sidebar {
      left: auto;
      right: 0;
      @include transform-translate-x($sidebar-width);
    }
  }

  .bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 1;
    top: 0;
    right: 0;
    left: $sidebar-width;
    content: '';
    z-index: 9999;
    overflow-x: hidden;
    background-color: transparent;
    @include transitions(0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));
  }

  .wrapper {
    @include transitions(0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));
  }
}

@include media-breakpoint-down(sm) {
  .sidebar {
    padding-top: 51px;

    .sidebar-wrapper {
      bottom: 0;
      height: 100%;
      display: flex;
      .nav {
        li {
          a {
            .sidebar-normal {
              letter-spacing: 0.03em;
            }
          }
        }
      }
      .sidebar_card {
        height: auto;
        width: calc(100% - 30px);
        justify-content: center;
        display: flex;
        h2 {
          margin-bottom: 10px;
        }
        button {
          margin: 0px;
          align-items: flex-start !important;
        }
      }
    }
  }
}

// @include media-breakpoint-between(md, lg) {
//   .wrapper {
//     @include transitions(0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));
//   }

//   .sidebar {
//     display: block;
//     top: 0;
//     height: 100vh;
//     width: $sidebar-width !important;
//     right: auto;
//     left: 0;
//     margin: 0;
//     border-radius: 0;
//     z-index: 1032;
//     visibility: visible;
//     overflow-y: visible;
//     padding: 0;
//     @include transition($sidebar-transition);
//     @include transform-translate-x(-362px);

//     // .sidebar-wrapper {
//     //   height: 100vh;
//     // }
//   }

//   .main-panel {
//     @include transition($sidebar-transition);
//   }

//   .nav-open {
//     .main-panel {
//       filter: blur(20px);
//       -webkit-filter: blur(20px);
//       right: 0;
//       @include transform-translate-x($sidebar-width);
//     }

//     .sidebar {
//       @include transition($sidebar-transition);
//       @include transform-translate-x(0px);
//       &:before {
//         content: unset;
//       }
//     }

//     body {
//       // position: relative;
//       // overflow-x: hidden;
//     }

//     .menu-on-right {
//       .main-panel {
//         @include transform-translate-x(-$sidebar-width);
//       }

//       .navbar-collapse,
//       .sidebar {
//         @include transform-translate-x(0px);
//       }

//       .bodyClick {
//         right: $sidebar-width;
//         left: auto;
//       }
//     }
//   }

//   .menu-on-right {
//     .sidebar {
//       left: auto;
//       right: 0;
//       @include transform-translate-x($sidebar-width);
//     }
//   }

//   .bodyClick {
//     height: 100%;
//     width: 100%;
//     position: fixed;
//     opacity: 1;
//     top: 0;
//     right: 0;
//     left: $sidebar-width;
//     content: "";
//     z-index: 9999;
//     overflow-x: hidden;
//     background-color: transparent;
//     @include transitions(0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));
//   }

//   .sidebar-mini .sidebar .logo a.logo-normal {
//     opacity: 1;
//     transform: translate3d(0px, 0, 0);
//   }

//   .sidebar-mini .sidebar .sidebar-wrapper > .nav li > a p {
//     opacity: 1;
//   }

//   .sidebar-mini .sidebar .sidebar-wrapper .nav li a p {
//     opacity: 1 !important;
//     transform: translate3d(0px, 0, 0);
//   }
// }
