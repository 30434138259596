.banner {
  height: 459px;
  background: #3b0d65;
  position: relative;
  width: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner::before {
  content: '';
  position: absolute;
  border: 1px dashed white;
  height: 93%;
  width: 95%;
}

.wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
  z-index: 12;
}

.mainTitle {
  font-size: 20px;
  font-weight: 400;
  line-height: 27.66px;
  color: #ffb131 !important;
  text-align: center;
  margin-bottom: 0;
}

.desc {
  font-family: Open Sans;
  font-size: 13px;
  font-weight: 700;
  line-height: 13.39px;
  text-align: center;
  color: #ffffff !important;
  width: 250px;
  margin-bottom: 10px;
  span {
    font-family: Open Sans;
    font-size: 17.91px;
    font-weight: 700;
    line-height: 18.44px;
    text-align: center;
  }
}

.action {
  margin-top: 10px;
  margin-bottom: 8px;
  display: flex;
  justify-content: center;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e9f6fc;
    width: 116px;
    height: 30px;
    border-radius: 5.05px;
    font-family: Open Sans;
    font-size: 12.47px;
    font-weight: 600;
    line-height: 16.99px;
    text-align: center;
    border: none;
    color: #000000 !important;
  }
}

.subDesc {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 14.42px;
  text-align: center;
  color: #ffb131 !important;
}

.photo {
  height: 150px;
}
