.header_error__wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
  @media (min-width: 992px) {
    padding-left: 35px;
    padding-right: 35px;
    padding-top: 25px;
  }
}

.report_button {
  display: flex;
  padding: 6px 16px;
  justify-content: center;
  align-items: center;
  gap: 6px;

  border-radius: 6.22px;
  background: rgba(87, 59, 118, 0.1);
  border: none;

  color: #573b76;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  @media (min-width: 992px) {
    font-size: 18px;
    padding: 8px 21px;
    gap: 8px;
    border-radius: 8px;
  }
}

.logo {
  width: 91px;
  @media (min-width: 992px) {
    width: 189px;
  }
}

.popover {
  position: absolute;
  right: -90px;

  border-radius: 20px;
  background: #fff;
  box-shadow: -3px 7px 22px 0px rgba(0, 0, 0, 0.1);
  padding: 20px;
  @media (max-width: 992px) {
    padding: 17px;
  }
}

.popover_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 19px;
}
.popover_header__title {
  color: rgba(0, 0, 0, 0.5) !important;

  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0;
  @media (max-width: 992px) {
    font-size: 12px;
  }
}

.report_wrap {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.report_value {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 15px 87px 13px 14px;
  border-radius: 12px;
  border: 1px solid transparent;
  background: rgba(87, 59, 118, 0.1);
  white-space: nowrap;
  transition: all 0.5 ease-in-out;
  @media (max-width: 992px) {
    gap: 14px;
    padding: 13px 88px 11px 12px;
  }

  p {
    color: #000 !important;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    @media (max-width: 992px) {
      font-size: 12px;
    }
  }
}

.report_value.selected {
  border: 1px solid #573b76;
  background: rgba(87, 59, 118, 0.2);
}

.textarea_wrap {
  margin-top: 18px;
  position: relative;
}

.text_area {
  resize: none;
  width: 100%;

  padding-top: 10px;
  padding-left: 19px;

  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  height: 113px;

  &::placeholder {
    color: rgba(0, 0, 0, 0.2) !important;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  &:focus {
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
}

.send_button {
  position: absolute;
  right: 10px;
  bottom: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 3px 11px 3px 11px;
  border-radius: 7px;
  background: #ddd8e4;
  border: none;
  color: #573b76 !important;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  &:disabled {
    background: #f3ecfe;
  }
}

.popover_success {
  position: absolute;
  right: -90px;

  border-radius: 20px;
  background: #fff;
  box-shadow: -3px 7px 22px 0px rgba(0, 0, 0, 0.1);
  padding: 24px 35px;
  @media (min-width: 992px) {
    padding: 35px 40px;
  }
}

.button_wrap {
  display: flex;
  justify-content: center;
}

.button_success {
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  background: rgba(87, 59, 118, 0.1);

  color: #573b76;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: 0;
}

.header_success {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 12px;
  right: 9px;
  cursor: pointer;
  @media (min-width: 992px) {
    top: 14px;
    right: 24px;
  }
}

.icon_wrap {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.title_success {
  color: #000 !important;
  text-align: center;
  width: 245px;
  font-family: Open Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 150% */
  margin-bottom: 25px;
  @media (min-width: 992px) {
    color: #000 !important;
    text-align: center;
    width: 286px;
    font-family: Open Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 35px; /* 145.833% */
    margin-bottom: 38px;
  }
}

.text_success {
  color: #000 !important;
  text-align: center;

  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 175% */
  margin-bottom: 25px;
  @media (min-width: 992px) {
    color: #000;
    text-align: center;

    font-family: Open Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }
}
