.banner {
  width: 914px;
  height: 512px;
  background: #3b0d65;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .bg {
    position: absolute;
    bottom: 0;
    z-index: 2;
    left: 0;
  }

  &::after {
    content: '';
    position: absolute;
    border: 1px dashed white;
    height: 93%;
    width: 95%;
  }
}

.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 12;
}

.mainTitle {
  font-size: 34px;
  font-weight: 400;
  line-height: 1;
  color: #ffb131 !important;
}

.desc {
  font-family: Open Sans;
  font-size: 28px;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  color: #ffffff !important;
  width: 634px;
  margin-bottom: 22px;

  span {
    font-family: Open Sans;
    font-size: 38px;
    font-weight: 700;
    line-height: 1;
    text-align: center;
  }
}

.action {
  display: flex;
  align-items: center;
  gap: 14px;
  margin-bottom: 8px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 183px;
  height: 42px;
  border-radius: 8px;
  opacity: 0px;
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: black !important;
  border: none;
  transition: all 0.4s ease-in;
  &:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
    transition: all 0.3s ease-out;
    color: black !important;
  }
}

.sign {
  background-color: #fceeee;
}

.download {
  background-color: #e9f6fc;
}

.subDesc {
  font-family: Open Sans;
  font-size: 17px;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  color: #ffb131 !important;
}

.topImg {
  height: 200px;
}

.centerImg {
  height: 174px;
}

.titleImg {
  position: absolute;
  top: -47px;
  right: 55px;
}
