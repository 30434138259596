.loader {
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  max-height: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: flex-end;
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;

    @media (min-width: 768px) {
      margin-bottom: 0;
      margin-right: 20px;
    }
  }

  &__logo {
    position: relative;
    height: 60px;
    margin-right: 20px;
    flex: none;

    @media (min-width: 768px) {
      height: 80px;
    }

    img {
      height: 100%;
      display: block;
    }
  }

  &__spinner {
    position: absolute;
    top: 16px;
    left: 16px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #fff;
    animation: spiner-logo 1.5s linear infinite;

    @media (min-width: 768px) {
      top: 20px;
      left: 22px;
      height: 42px;
      width: 42px;
    }
  }

  &__text {
    width: 155px;

    @media (min-width: 768px) {
      width: 200px;
    }
  }
}

.loader-dots {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #333333;
  color: #333333;
  box-shadow:
    9984px 0 0 0 #333333,
    9999px 0 0 0 #333333,
    10014px 0 0 0 #333333;
  animation: dotTyping 1s infinite linear;
  margin: 0 0 20px 10px;
}

@keyframes dotTyping {
  0% {
    box-shadow:
      9984px 0 0 0 #333333,
      9999px 0 0 0 #333333,
      10014px 0 0 0 #333333;
  }
  16.667% {
    box-shadow:
      9984px -10px 0 0 #333333,
      9999px 0 0 0 #333333,
      10014px 0 0 0 #333333;
  }
  33.333% {
    box-shadow:
      9984px 0 0 0 #333333,
      9999px 0 0 0 #333333,
      10014px 0 0 0 #333333;
  }
  50% {
    box-shadow:
      9984px 0 0 0 #333333,
      9999px -10px 0 0 #333333,
      10014px 0 0 0 #333333;
  }
  66.667% {
    box-shadow:
      9984px 0 0 0 #333333,
      9999px 0 0 0 #333333,
      10014px 0 0 0 #333333;
  }
  83.333% {
    box-shadow:
      9984px 0 0 0 #333333,
      9999px 0 0 0 #333333,
      10014px -10px 0 0 #333333;
  }
  100% {
    box-shadow:
      9984px 0 0 0 #333333,
      9999px 0 0 0 #333333,
      10014px 0 0 0 #333333;
  }
}

@keyframes spiner-logo {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
