.form_container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.upload__files_container {
  padding: 0 60px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 200px;

  @media (max-width: 639px) {
    padding: 0;
  }
}
.drag__file_area {
  border: 1px dashed #1e1e1e;
  border-radius: 10px;
  width: 100%;
  @media (max-width: 400px) {
    width: 249.05px;
  }
}
.area__title {
  margin: 0;
  margin-left: 18px;
  font-family: "Open Sans";
  font-style: normal;
  font-size: 16.25px;
}
.area__title_file {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16.25px;
  border-bottom: 1px solid #1e1e1e;
}
.upload__container {
  padding: 15px 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 400px) {
    padding: 4px 10px;
  }
}

.default__file_input {
  display: none;
}
.file__block {
  color: #1e1e1e;

  display: flex;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;
}

.file__name {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;

  border-bottom: 1px solid #1e1e1e;
  color: #1e1e1e;
}

.upload_button {
  border-radius: 8px;
  box-shadow: 0px 0px 14.3625px rgba(238, 105, 45, 0.9);
  color: #fff;
  text-transform: uppercase;
  padding: 9px 50px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 1.3;
  border: none;
  outline: none;
  background: linear-gradient(273.5deg, #ee7734 -12.6%, #ef3616 107.81%);
}

input[type="checkbox"] {
  position: relative;
  cursor: pointer;
}
input[type="checkbox"]:before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  border-radius: 3px;
  background: #fb4f31;
}
input[type="checkbox"]:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 2px;
  left: 6px;
}

.sweet_alert_button {
  width: 154px;
  height: 40px;
  outline: none;
  border: none;
  color: white;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 1.2;
  color: #ffffff;
  background: linear-gradient(273.5deg, #ee7734 -12.6%, #ef3616 107.81%);
  box-shadow: 0px 0px 14.3625px rgba(238, 105, 45, 0.9);
  border-radius: 10px;
}

.title__toast {
  font-size: 1rem;
}
