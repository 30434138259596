.err-page {
  background-color: #fbf6f6;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  &--small {
    // padding: 60px 15px 0;
  }

  &__wrap {
    flex: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__img {
    width: 198px;
    margin-bottom: 43px;

    svg {
      width: 100%;
      height: auto;
    }
  }

  &__title {
    font-size: 18px;
    line-height: 1.4;
    font-weight: 600;
    color: #ef3616;
    margin-bottom: 10px;
    text-align: center;
    max-width: 360px;
  }

  &__subtitle {
    font-size: 15px;
    line-height: 1.4;
    color: #000000 !important;
    margin-bottom: 39px;
    text-align: center;
    max-width: 283px;
    margin-top: 15px;
  }

  &__btn {
    width: 100%;
    max-width: 340px;
    min-height: 52px;
    margin: 0 !important;
    font-size: 20px;
    font-weight: 600 !important;
    line-height: 1;
    padding: 8px;
  }
}

@media (min-width: 1200px) {
  .err-page {
    &--small {
      // padding: 76px 15px 15px 195px;
    }

    &__img {
      width: 304px;
    }

    &__title {
      font-size: 18px;
    }

    &__subtitle {
      font-size: 14px;
      max-width: 750px;
    }

    &__btn {
      min-height: 56px;
    }
  }
}
