.navbar-container {
  display         : flex;
  justify-content : space-between;
  align-items     : center;
  background-color: #ffffff;
  width           : 100%;
  height          : 100%;
  max-width       : 1236px;
  margin          : 0 auto;
  padding         : 0 24px;
  flex            : auto;
}

.nav-logo {
  display        : flex;
  align-items    : center;
  justify-content: center;
  width          : 99px;
}

.nav-logo img {
  width     : 100%;
  height    : auto;
  object-fit: contain;
}

.navbar-inner {
  width                    : auto;
  min-width                : 270px;
  position                 : fixed;
  top                      : 0;
  right                    : 0;
  display                  : flex;
  flex-direction           : column;
  align-items              : center;
  background-color         : #ffffff;
  padding                  : 10rem 1rem 2.6rem;
  z-index                  : 50;
  border-bottom-left-radius: 40px;
  height                   : calc(var(--vh, 1vh) * 100);
  z-index                  : 992;
  right                    : -110vw;
  transition               : right 0.3s;
}

.navbar-inner.active {
  right: 0;
}

.navbar-center {
  display       : flex;
  flex-direction: column;
  align-items   : flex-end;
  width         : 100%;
  padding-right : 1rem;
}

.ielts-button {
  text-decoration : none;
  color           : #140237;
  font-family     : Open Sans;
  font-weight     : 600 !important;
  font-size       : 18px;
  border          : none;
  outline         : none !important;
  background-color: transparent;
  transition      : all 0.3s;
  position        : relative;
}

.ielts-button.active {
  background-image       : none;
  color                  : #ef3616 !important;
  -webkit-text-fill-color: #ef3616;
  font-weight            : 700;
}

.ielts-button+.ielts-button {
  margin-top: 28px;
}

.navbar-center a:active {
  color: #ee7734;
}

.navbar-btn {
  font-size      : 12px;
  line-height    : 1;
  font-weight    : 600 !important;
  letter-spacing : 0.03em;
  padding        : 4px;
  display        : flex;
  align-items    : center;
  justify-content: center;
  width          : 225px;
  min-height     : 42px;
  margin         : 0;
  flex           : none;
}

.navbar-btn--small {
  width: 100%;


}

.navbar-btn.navbar-btn--white,
.navbar-btn.navbar-btn--white:active {
  background      : none !important;
  background-color: #fff !important;
  color           : #1f0644 !important;
  box-shadow      : 0px 8.85363px 20.1219px rgba(32, 32, 32, 0.2) !important;
}

.navbar-btn+.navbar-btn {
  margin-top : 20px;
  margin-left: 0 !important;
}

.navbar-right-side.reverse .navbar-btn+.navbar-btn {
  margin-top   : 0;
  margin-bottom: 20px;
}

.nav-open .mobile-footer-btn {
  filter        : blur(20px);
  pointer-events: none;
}

.mobile-footer-btn {
  position        : fixed;
  bottom          : 0;
  left            : 0;
  right           : 0;
  top             : auto;
  width           : 100%;
  padding         : 20px 11px;
  background-color: #fff;
  box-shadow      : 0px 3px 7px #000;
  z-index         : 990;
  display         : flex;
  opacity         : 0;
  align-items     : center;
  justify-content : center;
}

.mobile-footer-btn.reverse {
  flex-direction: row-reverse;
}

.mobile-footer-btn.active {
  animation: show-navbar 0.3s linear forwards;
}

.mobile-footer-btn .navbar-btn,
.mobile-footer-btn .result-btn {
  width: auto;
  flex : auto;
}

.mobile-footer-btn:not(.reverse) .navbar-btn+.navbar-btn {
  margin-top : 0;
  margin-left: 12px !important;
}

.mobile-footer-btn.reverse .navbar-btn+.navbar-btn {
  margin-top  : 0;
  margin-right: 12px !important;
}

.navbar-btn__icon {
  width          : 20px;
  height         : 20px;
  display        : flex;
  align-items    : center;
  justify-content: center;
  margin-right   : 6px;
}

.navbar-btn__icon svg {
  max-width : 100%;
  max-height: 100%;
}

.plan-card-button h5 {
  font-family   : Open Sans;
  font-size     : 18px;
  line-height   : 25px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color         : #ffffff;
}

.navbar-right-side {
  display        : flex;
  flex-direction : column;
  justify-content: flex-end;
  align-items    : flex-end;
  margin-top     : auto;
}

.navbar-right-side.reverse {
  flex-direction: column-reverse;
}

.nav-button-wrap {
  display        : none;
  align-items    : center;
  justify-content: center;
  margin-left    : auto;
  margin-right   : 1rem;
}

.mobile-nav-button {
  font-family    : Open Sans;
  padding        : 0.2rem 0.5rem;
  min-height     : 40px;
  font-size      : 9px;
  font-weight    : normal;
  color          : white;
  border-radius  : 6px;
  background     : linear-gradient(273.5deg, #ee7734 -12.6%, #ef3616 107.81%);
  box-shadow     : 0px 11px 25px rgba(252, 86, 54, 0.2);
  margin-bottom  : 0 !important;
  display        : flex;
  align-items    : center;
  justify-content: center;
}

.navbar-wrap {
  height  : 64px;
  position: relative;
  z-index : 999;
}

.navbar-wrap.fixed .nav-bar-fix {
  position  : fixed;
  top       : 0;
  left      : 0;
  right     : 0;
  z-index   : 999;
  height    : 64px;
  box-shadow: 0px -3px 7px #000;
  animation : show-navbar 0.3s linear forwards;
}

.navbar-wrap.fixed .menu-item:not(.active) {
  display: none;
}

.navbar-wrap.fixed .nav-button-wrap {
  display: flex;
}

.nav-bar-fix.shodow {
  box-shadow: 0px -3px 7px #15023b;
}

.nav-bar-fix {
  width           : 100%;
  height          : 100%;
  background-color: #fff;
}

@keyframes show-navbar {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.burger-trigger {
  display         : flex;
  flex-direction  : column;
  align-items     : flex-end;
  justify-content : center;
  background-color: transparent;
  border          : none;
  width           : 24px;
  height          : 24px;
  padding         : 0;
  position        : relative;
  z-index         : 999;
}

.burger-trigger:focus {
  outline: none !important;
}

.burger-trigger span {
  width           : 20px;
  height          : 2px;
  border-radius   : 1px;
  border          : none;
  background-image: linear-gradient(286.49deg, #3d0f65 -8.45%, #140237 106%);
  transition      : all 0.3s;
}

.burger-trigger span:nth-child(2n) {
  width: 10px;
}

.burger-trigger span+span {
  margin-top: 2px;
}

.burger-trigger.active span+span {
  margin: 0;
}

.burger-trigger.active span:nth-child(2n) {
  opacity: 0;
}

.burger-trigger.active span {
  position: absolute;
  top     : 50%;
  left    : 50%;
}

.burger-trigger.active span:nth-child(1) {
  transform: translate(-50%, -50%) rotate(45deg);
}

.burger-trigger.active span:nth-child(3) {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.nav-open .landing-wrap {
  background-color: #f5f6fa;
  filter          : blur(20px);
  pointer-events  : none;
}

.nav-open .footer {
  filter        : blur(20px);
  pointer-events: none;
}

@media screen and (min-width: 500px) {
  .mobile-nav-button {
    font-size: 15px;
  }
}

@media screen and (min-width: 991px) {
  .menu-item {
    display: none;
  }

  .nav-logo {
    width: 140px;
  }

  .nav-button-wrap {
    display: none !important;
  }

  .navbar-inner {
    position      : static;
    transform     : translateX(0) !important;
    padding       : 0;
    height        : 100%;
    width         : 100%;
    gap           : 0;
    flex-direction: row;
  }

  .navbar-center {
    flex-direction : row;
    align-items    : center;
    justify-content: center;
    padding-right  : 0;
    margin         : 0 30px;
  }

  .navbar-right-side {
    flex-direction: row;
    margin        : 0;
  }

  .navbar-right-side.reverse {
    flex-direction: row-reverse;
  }

  .navbar-btn {
    font-size: 16px;
    width    : auto;
    min-width: 165px;
  }

  .navbar-btn--small {
    min-width: 110px;
  }

  .navbar-btn+.navbar-btn {
    margin-left  : 8px !important;
    margin-top   : 0;
    margin-bottom: 0 !important;
  }

  .navbar-right-side.reverse .navbar-btn+.navbar-btn {
    margin-left : 0 !important;
    margin-right: 8px;
  }

  .ielts-button {
    font-size: 16px;
  }

  .ielts-button+.ielts-button {
    margin-top : 0;
    margin-left: 28px;
  }

  .ielts-button::after {
    content         : '';
    position        : absolute;
    bottom          : 0;
    left            : 0;
    width           : 100%;
    height          : 1px;
    background-color: #ef3616;
    transition      : all 0.3s;
    transform       : scale(0);
    transform-origin: 50% 50%;
  }

  .ielts-button:not(.active):hover::after {
    transform: scale(1);
  }

  .mobile-footer-btn {
    display: none !important;
  }
}

/* 
@media (min-width: 1024px) {

  .ielts-button+.ielts-button {
    margin-left: 20px;
  }
} */

@media screen and (min-width: 1100px) {

  .navbar-btn {
    min-width: 225px;
  }

  .navbar-btn--small {
    min-width: 140px;
  }

  .ielts-button {
    font-size: 16px;
  }

  /* 
  .ielts-button+.ielts-button {
    margin-left: 28px;
  } */
}

@media screen and (min-width: 1400px) {

  .navbar-btn--small {
    min-width: 225px;
  }
}

@media screen and (max-width: 990px) {
  .navbar-container {
    height: 64px;
  }
}

.new-button {
  width                   : 336.11px;
  height                  : 44px;
  background              : linear-gradient(273.5deg, #ee7734 -12.6%, #ef3616 107.81%);
  box-shadow              : 0px 9.74px 22.13px 0px #fc563633;
  font-family             : Open Sans;
  font-size               : 16px;
  font-weight             : 600;
  line-height             : 21.79px;
  text-align              : left;
  text-underline-position : from-font;
  text-decoration-skip-ink: none;
  color                   : white;
  display                 : flex;
  align-items             : center;
  justify-content         : center;
  gap                     : 12px;
  border                  : none;
  border-radius           : 6.68px;
  transition              : transform 0.2s ease, box-shadow 0.2s ease, background 0.3s ease;
}

.new-button:hover {
  background: linear-gradient(273.5deg, #ef3616 -12.6%, #ee7734 107.81%);
  box-shadow: 0px 12px 30px 0px #fc563699;
  transform : translateY(-2px) scale(1.03);
}

@media screen and (max-width: 768px) {
  .new-button {
    width                   : 100%;
    height                  : 47px;
    font-family             : Open Sans;
    font-size               : 14px;
    font-weight             : 600;
    line-height             : 19.07px;
    letter-spacing          : 0.03em;
    text-align              : left;
    text-underline-position : from-font;
    text-decoration-skip-ink: none;
  }
}