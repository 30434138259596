.myModalStyle {
  width: 1050px !important;
  max-width: 100vw !important;
  top: 20%;
  @media (max-width: 1060px) {
    max-width: 70vw !important;
  }

  @media (max-width: 590px) {
    margin: 0 auto;
  }
  @media (max-width: 450px) {
    max-width: 85vw !important;
  }
}

.custom_modal {
  border-radius: 20px !important;
}

.custom__close {
  border: none;
  outline: none;
  background: transparent;

  @media (max-width: 540px) {
    display: none;
  }
}

.modal__container {
  display: flex;
  justify-content: space-around;

  p {
    color: black;
  }
  @media (max-width: 400px) {
    display: block;
  }
}

.modal__left {
  text-align: center;
  padding-top: 52px;
  width: 50%;
  p {
    color: black;
  }
  @media (max-width: 1060px) {
    display: block;
    width: 100%;
  }

  @media (max-width: 540px) {
    padding: 15px;
  }
}

.modal__right {
  padding-top: 50px;
  padding-right: 43px;
  padding-bottom: 77px;
  width: 50%;
  @media (max-width: 1060px) {
    display: none;
  }
}

.modal__card {
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: flex;

  margin: 10px;
  height: 100px;
  align-items: center;

  background: #ffffff;
}

.modal__card_title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;

  margin: 0;
  color: #1e1e1e;
}
.modal__card_desc {
  color: #1e1e1e;
}
.modal__title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  margin: 0;

  color: #1e1e1e;

  @media (max-width: 540px) {
    font-size: 15.977px;
  }
}

.modal__price {
  display: flex;
  justify-content: center;
  text-align: start;
}

.modal__price_num {
  font-weight: 800;
  font-size: 31px;
  line-height: 1.3;

  margin: 0;

  @media (max-width: 540px) {
    font-size: 20.2765px;
  }
}

.progress_bar {
  background-color: black !important;
}

.line {
  border: 1px solid rgba(0, 0, 0, 0.2);
  height: 400px;
  position: absolute;
  top: 10%;
  margin-right: 50px;
  @media (max-width: 1060px) {
    display: none;
  }
}
