.error_loading__section {
  margin-top: 70px;
  @media (min-width: 992px) {
    margin-top: 36px;

    @media (max-height: 770px) {
      margin-top: 20px;
    }
  }
}

.error_loading__content {
  display: flex;
  flex-direction: column;
  text-align: center;
  @media (min-width: 992px) {
    flex-direction: unset;
    justify-content: center;
    gap: 8px;
  }
}

.error_loading__title,
.error_loading__loading {
  color: #000 !important;
  text-align: center;
  font-family: Open Sans;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0;
  @media (min-width: 992px) {
    font-size: 36px;
  }
}

.error_wait {
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.error_wait__title {
  color: #000 !important;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
  position: relative;
  padding-left: 11px;
  padding-right: 11px;
  @media (min-width: 992px) {
    font-size: 20px;
  }
}

.progress_block {
  display: flex;
  justify-content: center;
  margin-top: 44px;
  margin-bottom: 35px;
  @media (min-width: 992px) {
    margin-top: 55px;
    margin-bottom: 62px;

    @media (max-height: 740px) {
      margin-top: 30px;
      margin-bottom: 45px;
    }

    @media (max-height: 700px) {
      margin-top: 20px;
      margin-bottom: 30px;
    }
  }
}

.progress_bar {
  width: 288px;
  border-radius: 9px;
  background: #dcdcdc;

  @media (min-width: 992px) {
    width: 754px;
    height: 35px;
  }

  .progress_inner {
    transition: width 0.5s ease-in-out;
    height: 28px;
    background: #13b222;
    text-align: end;
    padding-right: 10px;
    line-height: 27px;
    color: white;
    border-radius: 9px 0 0 9px;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    @media (min-width: 992px) {
      height: 35px;
      font-size: 16px;
      line-height: 33px;
    }
  }
}

.loading_wrap {
  display: flex;
  justify-content: center;
}

.loading_block {
  display: flex;
  align-items: center;

  border-radius: 30px;
  background: #fff;

  height: 42px;
  justify-content: center;

  gap: 6px;

  padding-left: 6px;
  padding-right: 18px;

  @media (min-width: 992px) {
    gap: 8px;
    height: 56px;
    padding-left: 8px;
    padding-right: 24px;
  }
}

.setting_block {
  display: flex;
  width: 29px;
  height: 29px;
  justify-content: center;
  align-items: center;
  border-radius: 81px;
  background: #fff;
  box-shadow: -2px 5px 10px 0px rgba(0, 0, 0, 0.07),
    -2px 5px 10px 0px rgba(0, 0, 0, 0.07);

  @media (min-width: 992px) {
    width: 38px;
    height: 38px;
    box-shadow: -2.17143px 6.51429px 13.02857px 0px rgba(0, 0, 0, 0.07),
      -2.17143px 6.51429px 13.02857px 0px rgba(0, 0, 0, 0.07);
  }
}

.setting_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
    transition: transform 500ms ease-in-out;
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transition: transform 500ms ease-in-out;
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    transition: transform 500ms ease-in-out;
  }
}

.loading_text {
  color: #000 !important;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0;

  padding-right: 6px;
  @media (min-width: 992px) {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.line {
  width: 43.556px;
  height: 0.778px;
  background: #000;
}
