.banner {
  margin: -5px -15px 5px;
  height: 70px;
  flex: auto;

  &__wrap {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 19px 19px 19px 10px;
    background-color: #573b76;
    gap: 10px;
  }

  &__icon {
    width: 25px;
    flex: none;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  &__text {
    font-size: 11px;
    line-height: 1.4;
    color: #fff !important;
    font-weight: 600;
    margin: 0;
  }

  &__btn {
    border: none;
    background-color: #ee7734;
    border-radius: 4px;
    padding: 9px 10px;
    min-height: 32px;
    font-size: 10px;
    font-weight: 700;
    line-height: 1;
    color: #ffffff;
    text-transform: uppercase;
    text-align: center;
    margin-left: auto;

    @media (min-width: 400px) {
      flex: none;
    }
  }

  &__close {
    padding: 0;
    border: none;
    background-color: transparent;
    width: 20px;
    height: 20px;
    position: relative;
    flex: none;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 2px;
      height: 100%;
      border-radius: 1px;
      background-color: #fff;
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}
