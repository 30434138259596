.reviewWrap {
  display: flex;
  justify-content: center;
  padding-top: 68px;
  padding-bottom: 32px;
  background: #faf7ff;

  @media (max-width: 992px) {
    padding-top: 32px;
  }
}

.reviewTitle {
  font-family: Open Sans;
  font-weight: 700;
  font-size: 29.82px;
  line-height: 100%;
  letter-spacing: 3%;
  color: #000000 !important;
  margin-bottom: 0;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    width: 412px;
    height: 3px;
    background: #ee7734;
    top: 101%;
    left: 2%;
  }

  @media (max-width: 992px) {
    font-size: 16px;
    &::after {
      content: '';
      width: 0;
    }
  }
}

.tipsWrap {
  padding: 0 38px;
}

.priceValue {
  width: 42px;
  height: 11px;
  background: #47ba52;
  border-radius: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 5px;
  line-height: 1;
  letter-spacing: -2%;
  color: #ffffff;
  text-align: center;

  @media (min-width: 768px) {
    width: 82px;
    height: 21px;
    font-weight: 600;
    font-size: 9.7px;
    line-height: 13.85px;
    letter-spacing: -2%;
    text-align: center;
  }
}

.skillValue {
  width: 52px;
  height: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fe9824b2;
  border-radius: 2px;
  font-family: Open Sans;
  font-weight: 700;
  font-size: 6px;
  line-height: 100%;
  letter-spacing: 0%;
  color: #ffffff;

  @media (min-width: 768px) {
    width: 100px;
    height: 24px;
    border-radius: 3.25px;
    background: #fe9824b2;
    font-family: Open Sans;
    font-weight: 700;
    font-size: 11.36px;
    line-height: 100%;
    letter-spacing: 0%;
  }
}

.JobValue {
  width: 48px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  background: #7816e51a;
  color: #7816e5;

  font-weight: 600;
  font-size: 6px;
  line-height: 1;
  letter-spacing: -2%;
  text-align: center;

  @media (min-width: 768px) {
    width: 93px;
    height: 24px;
    background: #7816e51a;
    font-weight: 600;
    font-size: 12.03px;
    line-height: 17.18px;
    letter-spacing: -2%;
    text-align: center;

    border-radius: 49.83px;
  }
}

.JobNumValue {
  background: #47ba521a;
  font-weight: 600;
  font-size: 6px;
  line-height: 1;
  letter-spacing: -2%;
  color: #47ba52;
  text-align: center;
  width: 43px;
  height: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 23px;
  @media (min-width: 768px) {
    width: 85px;
    height: 22px;
    font-weight: 600;
    font-size: 11.01px;
    line-height: 15.73px;
    letter-spacing: -2%;
    text-align: center;

    border-radius: 45.61px;
  }
}

.subjectValue {
  width: 48px;
  height: 12px;
  font-family: Open Sans;
  font-weight: 600;
  font-size: 5.79px;
  line-height: 100%;
  letter-spacing: 0%;
  color: #000000;
  background: #fdf1eb;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    width: 95px;
    height: 24px;
    font-family: Open Sans;
    font-weight: 600;
    font-size: 11.36px;
    line-height: 100%;
    letter-spacing: 0%;
  }
}

.courseValue {
  width: 47px;
  height: 12px;
  font-weight: 600;
  font-size: 6.12px;
  line-height: 8.75px;
  letter-spacing: -2%;
  text-align: center;
  color: #000000 !important;
  background: #f5f5f5;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    width: 93px;
    height: 24px;
    font-weight: 600;
    font-size: 12.03px;
    line-height: 17.18px;
    letter-spacing: -2%;
    text-align: center;

    border-radius: 49.83px;
  }
}

.langValue {
  p {
    font-weight: 400;
    font-size: 5px;
    line-height: 140%;
    letter-spacing: 0%;
    color: #6b7280 !important;
    margin: 0 !important;

    @media (min-width: 768px) {
      font-weight: 400;
      font-size: 9.44px;
      line-height: 1;
      letter-spacing: 0%;
      vertical-align: middle;
      text-transform: capitalize;
    }
    span {
      font-weight: 700;
      color: #151316 !important;
    }
  }
}

.adminValue {
  width: 67px;
  height: 14px;
  background: #f2f2f2;
  font-weight: 600;
  font-size: 6px;
  line-height: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -2%;
  text-align: center;
  border-radius: 25px;
  color: #000000;

  @media (min-width: 768px) {
    width: 132px;
    height: 28px;
    font-weight: 600;
    font-size: 13.14px;
    line-height: 16.09px;
    letter-spacing: -2%;
    text-align: center;

    border-radius: 46.66px;
  }
}

.deadLineValue {
  width: 57px;
  height: 14px;
  font-weight: 600;
  font-size: 6px;
  line-height: 1;
  letter-spacing: -2%;
  text-align: center;
  color: white;
  background: #ce5783;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 27.94px;
  @media (min-width: 768px) {
    width: 112px;
    height: 29px;

    border-radius: 55.08px;

    font-weight: 600;
    font-size: 13.14px;
    line-height: 16.09px;
    letter-spacing: -2%;
    text-align: center;

    border-radius: 46.66px;
  }
}

.translateIcon {
  width: 16px;
  height: 16px;
  @media (min-width: 768px) {
    width: 32px;
    height: 32px;
  }
}

.partners {
  background: #18012d;
  height: 347px;
  width: 100%;
}

.partnersWrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  h3 {
    font-weight: 700;
    font-size: 39px;
    line-height: 115%;
    letter-spacing: 0%;
    text-align: center;
    color: #ffffff;
  }

  p {
    width: 612px;

    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    letter-spacing: 0%;
    text-align: center;
    margin-bottom: 0;
    color: #9a9a9a;

    @media (max-width: 1024px) {
      width: 88%;
    }
  }
}
